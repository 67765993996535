/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Fullmap, FullmapWrap, Image, SeparateLine, SeparateLineWrap, Text, Button, Title } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"La Kapo Barbershop"}>
        <Column className="js-anim  --anim2 --anim-s3 --left" anim={"2"} name={"uvod-2"} animS={"3"} style={{"backgroundColor":"rgba(241,236,205,1)"}}>
        </Column>


        <Column className="--left">
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 mb--0 mt--0 pb--0 pl--20 pr--20 pt--0 flex--center" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left">
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 mb--0 mt--0 pb--0 pl--20 pr--20 pt--0 flex--center" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"gvagkt918ph"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column name={"4zyfevec71k"}>
        </Column>


        <Fullmap className="--style2" name={"4tbscrqd7gw"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="pb--12 pt--40" name={"csw5apquixc"} layout={"l8"}>
          
          <ColumnWrap className="column__flex js-anim  --anim6 --center el--3 flex--center" anim={"6"} style={{"maxWidth":1090,"backgroundColor":"rgba(241,236,205,1)"}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19803/dcdfb070741b44b3a5e76c17b7540c49_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19803/dcdfb070741b44b3a5e76c17b7540c49_s=350x_.png 350w, https://cdn.swbpg.com/t/19803/dcdfb070741b44b3a5e76c17b7540c49_s=660x_.png 660w, https://cdn.swbpg.com/t/19803/dcdfb070741b44b3a5e76c17b7540c49_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19803/ea76e428f2424d71a99a5a0dd1d935a9_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19803/ea76e428f2424d71a99a5a0dd1d935a9_s=350x_.png 350w, https://cdn.swbpg.com/t/19803/ea76e428f2424d71a99a5a0dd1d935a9_s=660x_.png 660w, https://cdn.swbpg.com/t/19803/ea76e428f2424d71a99a5a0dd1d935a9_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19803/5712152c87c344e783ac294825b6529b_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19803/5712152c87c344e783ac294825b6529b_s=350x_.png 350w, https://cdn.swbpg.com/t/19803/5712152c87c344e783ac294825b6529b_s=660x_.png 660w, https://cdn.swbpg.com/t/19803/5712152c87c344e783ac294825b6529b_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left">
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 mb--0 mt--0 pb--0 pl--20 pr--20 pt--0 flex--center" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"7z12pl247lk"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--left">
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 mb--0 mt--0 pb--0 pl--20 pr--20 pt--0 flex--center" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"mhn835omt6l"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box fs--16" style={{"maxWidth":654}} content={"<span style=\"color: var(--white);\">Jsme tradiční pánské holičství, které vytváří luxusní prostor pro opravdové gentlemany. Nechte se hýčkat v rukou profesionálních kadeřníků a unikněte od starostí každodenního života. Přijďte se nechat upravit a užít si trochu relaxu.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"6aut48cc2kc"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"pg9diquxq3d"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column name={"fzar8rl78j"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 fs--48" url={"https://la-kapo-barbershop.reservio.com/"} href={"https://la-kapo-barbershop.reservio.com/"} style={{"backgroundColor":"rgba(241,236,205,1)"}} content={"<span style=\"color: var(--color-dominant);\">Rezervace</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"fa9s479u8tv"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19803/c1aa7f2788eb437daff022797f5ecb1f_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/19803/c1aa7f2788eb437daff022797f5ecb1f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19803/c1aa7f2788eb437daff022797f5ecb1f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19803/c1aa7f2788eb437daff022797f5ecb1f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19803/c1aa7f2788eb437daff022797f5ecb1f_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box fs--48" content={"Nový Jičín"}>
              </Title>

              <Text className="text-box fs--26" style={{"maxWidth":467}} content={"Adresa: Žerotínova 14 čp. 63\n<br>Tel.: +420 703 979 705"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19803/ac25502ed0b74c2aa0ee4913ef5f7eed_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/19803/ac25502ed0b74c2aa0ee4913ef5f7eed_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19803/ac25502ed0b74c2aa0ee4913ef5f7eed_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19803/ac25502ed0b74c2aa0ee4913ef5f7eed_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19803/ac25502ed0b74c2aa0ee4913ef5f7eed_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19803/ac25502ed0b74c2aa0ee4913ef5f7eed_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box fs--48" content={"Valašské Klobouky"}>
              </Title>

              <Text className="text-box fs--26" style={{"maxWidth":467}} content={"Adresa: Komenského čp. 100/4\n<br>Tel.: +420 703 962 328"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"yqz0zpcsqra"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" name={"0h6p9bhaza7t"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--48" content={"<span style=\"background-color: rgb(241, 236, 205); color: var(--color-dominant);\">Náš tým</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wupcf7d821g"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/19803/7e10b9f0cf3d4da0af7b7331ccfe14b6_s=660x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/19803/7e10b9f0cf3d4da0af7b7331ccfe14b6_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/19803/7e10b9f0cf3d4da0af7b7331ccfe14b6_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/19803/7e10b9f0cf3d4da0af7b7331ccfe14b6_s=860x_.jpeg 860w"}>
              </Image>

              <Text className="text-box fs--48" content={"Jakub"}>
              </Text>

              <Image className="--shape5" src={"https://cdn.swbpg.com/t/19803/c2e1ad28db714b049ce6e101d4869b50_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/19803/c2e1ad28db714b049ce6e101d4869b50_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/19803/c2e1ad28db714b049ce6e101d4869b50_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/19803/c2e1ad28db714b049ce6e101d4869b50_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/19803/c2e1ad28db714b049ce6e101d4869b50_s=1400x_.jpeg 1400w"}>
              </Image>

              <Text className="text-box fs--48" content={"Tomáš"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/19803/15190f1d16834c0f99e3b5ee205e2dfd_s=660x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/19803/15190f1d16834c0f99e3b5ee205e2dfd_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19803/15190f1d16834c0f99e3b5ee205e2dfd_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19803/15190f1d16834c0f99e3b5ee205e2dfd_s=860x_.JPG 860w"}>
              </Image>

              <Text className="text-box fs--48" content={"Aron<br>"}>
              </Text>

              <Image className="--shape5" src={"https://cdn.swbpg.com/t/19803/798d5044e30c424e9cc0ba48538f5027_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/19803/798d5044e30c424e9cc0ba48538f5027_s=350x_.png 350w, https://cdn.swbpg.com/t/19803/798d5044e30c424e9cc0ba48538f5027_s=660x_.png 660w, https://cdn.swbpg.com/t/19803/798d5044e30c424e9cc0ba48538f5027_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box fs--48" content={"Samuel"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/19803/bb48b91156bb48ba97241c1e1ba684d2_s=660x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/19803/bb48b91156bb48ba97241c1e1ba684d2_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/19803/bb48b91156bb48ba97241c1e1ba684d2_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/19803/bb48b91156bb48ba97241c1e1ba684d2_s=860x_.jpeg 860w"}>
              </Image>

              <Text className="text-box fs--48" content={"Patrik"}>
              </Text>

              <Image className="--shape5" src={"https://cdn.swbpg.com/t/19803/9754c7929c55466abb59d71dadbb3cbe_s=660x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/19803/9754c7929c55466abb59d71dadbb3cbe_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/19803/9754c7929c55466abb59d71dadbb3cbe_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/19803/9754c7929c55466abb59d71dadbb3cbe_s=860x_.jpeg 860w"}>
              </Image>

              <Text className="text-box fs--48" content={"Oliver"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="css-1absx11 --style2" name={"zb0mmh2l4nn"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=3000x_.jpeg);
    }
  
    `}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <SeparateLine className="pb--10 pt--10" name={"uo82p9aht6j"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"29o0s2uovc"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --right el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--hvr4 btn-box--cbtn2 btn-box--sbtn2 btn-box--center fs--26" url={"https://www.instagram.com/la_kapo_barber/"} href={"https://www.instagram.com/la_kapo_barber/"} style={{"backgroundColor":"rgba(241,236,205,1)"}} content={"<span style=\"color: var(--color-blend--95);\">instagram</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr4 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--center fs--26" url={"https://www.facebook.com/lakapobarber"} href={"https://www.facebook.com/lakapobarber"} style={{"backgroundColor":"rgba(241,236,205,1)"}} content={"<span style=\"color: var(--color-blend--95);\">facebook</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"fvumcftdj6w"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}